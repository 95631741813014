import React from "react";
import "./HeroSection.css"; // Import the CSS file
import t1 from "../images/T1.png";
import t2 from "../images/T2.png";
import t4 from "../images/T4.png";

function HeroSection() {
  return (
    <section className="heroSection">
      <div className={"heroContainer"}>
        <div className={"heroContent"}>
          <div className={"heroTextContent"}>
            <h1 className={"heroTitle"}>
              Your All-in-One School Management Solution
            </h1>
            <p className={"heroDescription"}>
              Stay organized, informed, and prepared with ClassBoon, the
              comprehensive program that simplifies the day to day and
              priorities student safety.
            </p>
            <button className={"heroCta"}>Create an Account</button>
          </div>
          <div className={"heroImageContainer"}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ee65591f5296e3f8c2a355f9592b56e0bd21fffb6f166a96ff2bc1ce8410c5bd?apiKey=3aed63da8d6d4f219c9e16a0bf615bcf&"
              alt="School management solution"
              className={"heroImage"}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
