import React, { useState } from "react";
import { push, set, ref } from "firebase/database";
import { db } from "./FirebaseConfig";
import { firestore } from "./FirebaseConfig";
import "./SignOutForm.css";
import {
  getFirestore,
  query,
  where,
  getDocs,
  collection,
  doc,
  getDoc,
} from "firebase/firestore";
import useEnrolledStudents from "./useEnrolledStudents";
import { auth } from "./FirebaseConfig";
import { useUser } from "./UserContext";

const SignOutForm = () => {
  const [idNumber, setIdNumber] = useState("");
  const [location, setLocation] = useState("");
  const { enrolledStudentsMap } = useEnrolledStudents();
  const { schoolId } = useUser();
  const { user, loading } = useUser();
  console.log("School ID:", schoolId);

  const classroomName =
    user && user.displayName ? `${user.displayName}'s Classroom` : "Classroom";

  const handleChange = (e) => {
    console.log("New idNumber value:", e.target.value);
    setIdNumber(e.target.value);
  };

  const searchStudent = async (idNumber) => {
    try {
      const firestoreDb = getFirestore();

      if (idNumber) {
        console.log("Trying to find a student with this ID: ", idNumber);

        // Query students with the provided ID and the addedBy field equal to the user's UID
        const studentsRef = collection(firestoreDb, "students");

        // Query for documents where the "addedBy" field is equal to the user's UID
        const query1 = query(
          studentsRef,
          where("studentId", "==", idNumber),
          where("schoolId", "==", schoolId)
        );

        // Query for documents where the user's UID is in the "coTeachers" array
        const query2 = query(
          studentsRef,
          where("studentId", "==", idNumber),
          where("coTeachers", "array-contains", auth.currentUser.uid)
        );

        // Query for documents where the user's UID is in the "otherTeachers" array
        const query3 = query(
          studentsRef,
          where("studentId", "==", idNumber),
          where("otherTeachers", "array-contains", auth.currentUser.uid)
        );

        // Combine the results of the three queries
        const querySnapshot1 = await getDocs(query1);
        const querySnapshot2 = await getDocs(query2);
        const querySnapshot3 = await getDocs(query3);

        if (!querySnapshot1.empty) {
          console.log("Query 1 If statement is not empty");
          const foundStudentId = querySnapshot1.docs[0].id;
          return foundStudentId;
        } else if (!querySnapshot2.empty) {
          console.log("Query 2 If statement is not empty");
          const foundStudentId = querySnapshot2.docs[0].id;
          return foundStudentId;
        } else if (!querySnapshot3.empty) {
          console.log("Query 3 If statement is not empty");
          const foundStudentId = querySnapshot3.docs[0].id;
          return foundStudentId;
        } else {
          console.log("No matching student found");
          return null;
        }
      } else {
        console.log("Hitting the else statement");
        return null;
      }
    } catch (error) {
      console.error("Error searching for student:", error);
      return null;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted!");

    try {
      const foundStudentId = await searchStudent(idNumber);

      if (foundStudentId) {
        console.log("Student Document ID:", foundStudentId);

        // Check if the student is enrolled in the current user's classes
        const isEnrolled = enrolledStudentsMap[foundStudentId];

        if (isEnrolled) {
          // Retrieve student's name from Firestore using the document ID
          const studentDocRef = doc(
            collection(firestore, "students"),
            foundStudentId
          );
          const studentDocSnapshot = await getDoc(studentDocRef);
          const studentData = studentDocSnapshot.data();
          console.log("Student Data Grade: ", studentData.grade);

          if (studentData) {
            console.log("Student Name:", studentData.studentName);

            // Get the user's UID from Firebase Authentication
            const user = auth.currentUser;
            if (user) {
              const { uid } = user; // Extract the user's UID

              const signOutRef = push(ref(db, "signOuts"));
              const signOutId = signOutRef.key;

              const signOutData = {
                idNumber,
                studentDocumentNumber: foundStudentId,
                studentName: studentData.studentName,
                time_out: new Date().toString(),
                location,
                schoolId: studentData.schoolId,
                teacherUID: uid, // Add the user's UID to the sign-out data
              };

              // Check if grade exists before setting
              if (studentData.grade !== undefined) {
                signOutData.grade = studentData.grade;
              }

              await set(ref(db, `signOuts/${signOutId}`), signOutData);

              console.log("Sign-out data added successfully!");
            } else {
              console.log("User not authenticated.");
            }
          } else {
            console.log("Student not found!");
          }
        } else {
          console.log("Student is not enrolled in any of your classes.");
        }
      } else {
        console.log("Student not found!");
      }

      // Reset the form after submission
      setIdNumber("");
      setLocation("");
    } catch (error) {
      console.error("Error adding sign-out data:", error);
    }
  };

  return (
    <div className="form-container">
      <h2>{classroomName}</h2>
      <form className="form" onSubmit={handleFormSubmit}>
        <div className="form-row-input">
          <input
            type="text"
            placeholder="ID Number"
            value={idNumber}
            onChange={handleChange}
          />
          <select
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          >
            <option value="">Select Location</option>
            <option value="Bathroom">Bathroom</option>
            <option value="Water Fountain">Water Fountain</option>
            <option value="Nurse">Nurse</option>

            <option value="Other">Other</option>
          </select>
        </div>
        <button type="submit">Sign Out</button>
      </form>
    </div>
  );
};

export default SignOutForm;
