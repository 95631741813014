import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"; // Import Navigate
import { UserProvider } from "./components/UserContext";
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Updated import for auth

// Components related to navigation and authentication
import NavBar from "./components/NavBar";
import LogInForm from "./components/LogInForm";
import RegistrationForm from "./components/RegistrationForm";

// Components related to dashboard and main functionality
import Dashboard from "./components/Dashboard";
import LeftSidePanel from "./components/LeftSidePanel";

// Components related to home, about, and general content
import Home from "./components/Home";
import About from "./components/About";

// Components related to schools and classes
import Schools from "./components/Schools";
import Teachers from "./components/Teachers";
import ClassSignOutPage from "./components/ClassSignOutPage";
import AddClass from "./components/AddClass";
import ClassDetails from "./components/ClassDetails";
import DisplayClasses from "./components/DisplayClasses";

// Components related to student management
import StudentsPage from "./components/StudentsPage";
import AddStudent from "./components/AddStudent"; // Import the AddStudents component
import DisplayEnrolledStudents from "./components/DisplayEnrolledStudents";

// Components related to attendance and records
import Attendance from "./components/Attendance";
import AttendanceRecord from "./components/AttendanceRecord";
import SignOutsTeacherRecord from "./components/SignOutsTeacherRecord";

// Components related to emergencies and invitations
import Emergency from "./components/Emergency";
import Invitations from "./components/Invitations";
import SchoolList from "./components/SchoolList";
import SchoolListWithEmergencies from "./components/SchoolListWithEmergencies";

// Components with miscellaneous functionality
import AfterRegistrationSchoolSelection from "./components/AfterRegistrationSchoolSelection";

import "./App.css";

function App() {
  const [userLoggedIn, setUserLoggedIn] = React.useState(false); // State to track user login status

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User is logged in:", user.email);
        setUserLoggedIn(true); // Set userLoggedIn state to true if user is logged in
      } else {
        console.log("User is not logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <UserProvider>
        <div className="app-container">
          {/* Redirect to attendance page if user is logged in */}
          <NavBar />
          <div className="content-container">
            <Routes>
              {/* Routes with left side panel */}
              <Route
                path="/dashboard"
                element={
                  <>
                    <LeftSidePanel />
                    <Dashboard />
                  </>
                }
              />
              <Route
                path="/displayenrolledstudents"
                element={
                  <>
                    <LeftSidePanel />
                    <DisplayEnrolledStudents />
                  </>
                }
              />
              <Route
                path="/invitations"
                element={
                  <>
                    <LeftSidePanel />
                    <Invitations />
                  </>
                }
              />
              <Route
                path="/displayclasses"
                element={
                  <>
                    <LeftSidePanel />
                    <DisplayClasses />
                  </>
                }
              />
              <Route
                path="/studentspage"
                element={
                  <>
                    <LeftSidePanel />
                    <StudentsPage />
                  </>
                }
              />
              {/* Other routes without left side panel */}
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/register" element={<RegistrationForm />} />
              <Route path="/loginform" element={<LogInForm />} />
              <Route path="/classsignoutpage" element={<ClassSignOutPage />} />
              <Route path="/addclass" element={<AddClass />} />
              <Route
                path="/afterregistrationschoolselection"
                element={<AfterRegistrationSchoolSelection />}
              />
              <Route path="/addstudent/:classId" element={<AddStudent />} />
              <Route
                path="/signoutsteacherrecord"
                element={<SignOutsTeacherRecord />}
              />
              <Route path="/attendance" element={<Attendance />} />
              <Route path="/attendancerecord" element={<AttendanceRecord />} />
              <Route path="/classdetails" element={<ClassDetails />} />
              <Route path="/invitations" element={<Invitations />} />
              <Route path="/emergency" element={<Emergency />} />
              <Route path="/teachers" element={<Teachers />} />
              <Route path="/schools" element={<Schools />} />
              <Route path="/schoollist" element={<SchoolList />} />
              <Route
                path="/schoollistwithemergencies"
                element={<SchoolListWithEmergencies />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </UserProvider>
    </Router>
  );
}

function NotFound() {
  return <h1>404 Not Found</h1>;
}

export default App;
