import React, { useState, useRef, useContext, useEffect } from "react";
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  Timestamp,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { firestore, serverTimestamp } from "./FirebaseConfig";
import useFetchClasses from "./useFetchClasses";
import useFilteredEmergencyStudents from "./useFilteredEmergencyStudents";
import { UserContext, useUser } from "./UserContext";
import "./Emergency.css";

const Emergency = () => {
  const [selectedClassId, setSelectedClassId] = useState("");
  const [studentIdInput, setStudentIdInput] = useState("");
  const { classList } = useFetchClasses();
  const {
    notAccountedForStudents,
    accountedForStudents,
    refreshFilteredEmergencyStudents,
  } = useFilteredEmergencyStudents(selectedClassId);
  const studentIdInputRef = useRef();
  const { user } = useContext(UserContext); // Access user context
  const isDisabled = false; // Replace `false` with the appropriate value
  const { displayName } = useContext(UserContext);

  useEffect(() => {
    if (classList.length === 1) {
      setSelectedClassId(classList[0].id);
    }
  }, [classList]);

  const handleClassChange = (event) => {
    const selectedId = event.target.value;
    setSelectedClassId(selectedId);
  };

  const markEmergency = async (sid, isAccountedFor) => {
    try {
      if (!selectedClassId) {
        console.log("No class selected for emergency marking.");
        return;
      }

      const classRef = doc(firestore, "classes", selectedClassId);
      const emergencyCollectionRef = collection(classRef, "emergencies");

      const currentDate = new Date().toISOString().slice(0, 10);
      const emergencyDocRef = doc(
        emergencyCollectionRef,
        currentDate // Use current date as the document ID
      );

      const currentTimestamp = serverTimestamp();
      console.log("Current Timestamp before setting:", currentTimestamp); // Add this line

      // Update the emergency data structure
      const emergencyData = {
        timestamp: currentTimestamp,
        sid: sid,
        clickedBy: isAccountedFor ? null : user.displayName, // Use the user's displayName if marking, else null if deleting
      };
      console.log("Data: ", emergencyData);

      // Create a subcollection for each student
      const studentEmergencyCollectionRef = collection(
        emergencyDocRef,
        "students"
      );

      // Check if the student emergency document exists
      const studentEmergencyDocRef = doc(studentEmergencyCollectionRef, sid);

      if (isAccountedFor) {
        // Delete the document if it exists
        await deleteDoc(studentEmergencyDocRef);
        console.log("Emergency record deleted for student (sid):", sid);
      } else {
        // Create a document for the student's emergency data
        await setDoc(studentEmergencyDocRef, emergencyData, { merge: true });
        console.log("Emergency marked for student (sid):", sid);
      }

      refreshFilteredEmergencyStudents();
    } catch (error) {
      console.error("Error marking/deleting emergency:", error);
    }
  };

  // Function to handle manual student ID input
  const handleStudentIdInput = (event) => {
    setStudentIdInput(event.target.value);
  };

  const handleMarkByStudentId = async () => {
    try {
      if (!selectedClassId) {
        console.log("No class selected for emergency marking.");
        return;
      }

      const studentsCollectionRef = collection(firestore, "students");
      const q = query(
        studentsCollectionRef,
        where("studentId", "==", studentIdInput.trim())
      );
      console.log("Hitting the try in the Marked ID");

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No student found with the given student ID.");
        return;
      }

      const studentDoc = querySnapshot.docs[0];
      const sid = studentDoc.id;
      console.log("Student Doc: ", studentDoc);
      console.log("Student Id: ", sid);

      if (sid) {
        markEmergency(sid);
        setStudentIdInput(""); // Clear the input after marking emergency
        // Focus the input element again after emergency is marked
        studentIdInputRef.current.focus();
      } else {
        console.log("Student not found in the filtered list.");
      }
    } catch (error) {
      console.error("Error marking emergency:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleMarkByStudentId();
    }
  };

  const formatTimestamp = (timestamp) => {
    console.log("Timestamp in format: ", timestamp);

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return new Date(timestamp).toLocaleString(undefined, options);
  };

  return (
    <div className="emergency-container">
      <h2>Mark Emergency for Today</h2>
      {classList.length === 1 ? (
        <div>
          <span>{classList[0].className}</span>
        </div>
      ) : (
        <div>
          <label>Select Class: </label>
          <select className="class-select" onChange={handleClassChange}>
            <option value="">Select a class</option>
            {classList.map((cls) => (
              <option key={cls.id} value={cls.id}>
                {cls.className}
              </option>
            ))}
          </select>
        </div>
      )}{" "}
      {isDisabled && (
        <div className="manual-emergency">
          <label>Enter Student ID:</label>
          <input
            type="text"
            value={studentIdInput}
            onChange={handleStudentIdInput}
            onKeyPress={handleKeyPress}
            ref={studentIdInputRef}
          />
          <button className="red-buttons" onClick={handleMarkByStudentId}>
            Not Accounted For
          </button>
        </div>
      )}
      {/* Not Accounted For Students */}
      <div className="student-heading">
        <h3>Not Accounted For</h3>
      </div>
      <div className="student-grid">
        {notAccountedForStudents
          .sort((a, b) => {
            // Check if lastName is defined for both objects
            if (a.lastName && b.lastName) {
              return a.lastName.localeCompare(b.lastName); // Sort by last name if both last names are defined
            } else if (!a.lastName && b.lastName) {
              return 1; // Place the object with undefined lastName after the one with defined lastName
            } else if (a.lastName && !b.lastName) {
              return -1; // Place the object with defined lastName before the one with undefined lastName
            } else {
              // If both lastName properties are undefined, or if they are equal, compare by another property (e.g., studentName)
              return a.studentName.localeCompare(b.studentName);
            }
          })
          .map((student) => (
            <div
              key={student.id}
              className="student-item-not-accounted"
              onClick={() => markEmergency(student.id)}
            >
              <span className="student-name">{student.studentName}</span>
            </div>
          ))}
      </div>
      {/* Accounted for Students */}
      <div className="student-heading">
        <h3>Accounted For</h3>
      </div>
      <div className="student-grid">
        {accountedForStudents
          .sort((a, b) => {
            // Check if lastName is defined for both objects
            if (a.lastName && b.lastName) {
              return a.lastName.localeCompare(b.lastName); // Sort by last name if both last names are defined
            } else if (!a.lastName && b.lastName) {
              return 1; // Place the object with undefined lastName after the one with defined lastName
            } else if (a.lastName && !b.lastName) {
              return -1; // Place the object with defined lastName before the one with undefined lastName
            } else {
              // If both lastName properties are undefined, or if they are equal, compare by another property (e.g., studentName)
              return a.studentName.localeCompare(b.studentName);
            }
          })
          .map((student) => (
            <div
              key={student.id}
              className="student-item-accounted"
              onClick={() => markEmergency(student.id, true)} // Call markEmergency with isAccountedFor as true for deletion
            >
              <div className="accounted-for-content">
                <span className="student-name">{student.studentName}</span>
                <span className="clicked-by">{student.clickedBy}</span>
                <span className="timestamp smaller-timestamp">
                  {formatTimestamp(student.timestamp)}
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Emergency;
