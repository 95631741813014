// LeftSidePanel.js
import React from "react";
import { Link } from "react-router-dom";

import "./LeftSidePanel.css";

const LeftSidePanel = () => {
  return (
    <div className="left-side-panel">
      <ul>
        <li>
          <Link to="/dashboard">Dashboard</Link>
        </li>
        <li>
          <Link to="/displayclasses">Classes</Link>
        </li>
        <li>
          <Link to="/invitations">Invitations</Link>
        </li>
        <li>
          <Link to="/displayenrolledstudents">Students</Link>
        </li>
        {/* Add more navigation links as needed */}
      </ul>
    </div>
  );
};

export default LeftSidePanel;
