import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import LoginForm from "./LogInForm.js";
import RegistrationForm from "./RegistrationForm.js";

function LogInRegisterModal(props) {
  const [showLoginForm, setShowLoginForm] = useState(true);

  const toggleForm = () => {
    setShowLoginForm(!showLoginForm);
  };

  useEffect(() => {
    // Ensure login form is shown whenever the modal is opened
    setShowLoginForm(true);
  }, [props.show]);

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="custom-modal" // Add a custom class name
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {showLoginForm ? "Log In" : "Register"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showLoginForm ? <LoginForm /> : <RegistrationForm />}
      </Modal.Body>
      <Modal.Footer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button onClick={toggleForm}>
          {showLoginForm ? "Register" : "Already have an account?"}
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LogInRegisterModal;
