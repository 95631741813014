import React, { useState, useContext, useEffect } from "react";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getFirestore,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import { firestore, auth } from "./FirebaseConfig";
import useFetchClasses from "./useFetchClasses";
import { UserContext } from "./UserContext";
import { useParams } from "react-router-dom";
import DisplayEnrolledStudentsTable from "./DisplayEnrolledStudentsTable";

import "./AddStudent.css";

// Helper functions
const parseStudentList = (list) => {
  const lines = list.split("\n");
  return lines.map((line) => {
    const [fullName, id, grade] = line.split("\t");
    if (!fullName || !id || !grade) return {}; // Prevent incomplete data from causing issues
    const [firstName, ...lastNameArray] = fullName.split(" ");
    const lastName = lastNameArray.join(" ");
    return {
      studentName: fullName,
      firstName,
      lastName,
      id,
      grade,
      isValid: fullName && id && grade, // Basic validation
    };
  });
};

const validateStudents = (students) =>
  students.map((student) => ({
    ...student,
    isValid: student.studentName && student.id && student.grade, // Check if fields are non-empty
  }));

const gradeLevelOptions = [
  "Pre-K",
  "Kindergarten",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

const AddStudent = () => {
  const { user } = useContext(UserContext);
  const { classList } = useFetchClasses();
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [pastedStudentList, setPastedStudentList] = useState("");
  const [parsedStudents, setParsedStudents] = useState([]);
  const [fileError, setFileError] = useState(null);
  const { classId } = useParams();

  useEffect(() => {
    setSelectedClass(classId);
  }, [classId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const studentName = event.target.studentName.value;
    const studentId = event.target.studentId.value;

    try {
      const db = getFirestore();
      const studentsCollectionRef = collection(db, "students");

      const userRef = doc(db, "users", auth.currentUser.uid);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();

      const newStudentData = {
        studentName,
        studentId,
        grade: selectedGrade,
        enrolledClasses: [selectedClass],
        schoolId: userData.schoolId,
        addedBy: auth.currentUser.uid,
      };

      const studentDocRef = await addDoc(studentsCollectionRef, newStudentData);

      const classDocRef = doc(db, "classes", selectedClass);
      await updateDoc(classDocRef, {
        enrolledStudents: arrayUnion(studentDocRef.id),
      });

      event.target.reset();
      setSelectedClass("");
      setSelectedGrade("");
    } catch (error) {
      console.error("Error adding student:", error);
    }
  };

  const handleBulkImport = async () => {
    const validStudents = parsedStudents.filter((student) => student.isValid);

    if (selectedClass) {
      for (const student of validStudents) {
        await handleSubmitSingle(
          student.studentName,
          student.firstName,
          student.lastName,
          student.id,
          student.grade
        );
      }
    } else {
      console.log("No class selected for bulk import");
    }
  };

  const handleSubmitSingle = async (
    studentName,
    firstName,
    lastName,
    studentId,
    grade
  ) => {
    try {
      const db = getFirestore();
      const studentsCollectionRef = collection(db, "students");

      const userRef = doc(db, "users", auth.currentUser.uid);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();

      const newStudentData = {
        studentName,
        firstName,
        lastName,
        studentId,
        grade,
        enrolledClasses: [selectedClass],
        schoolId: userData.schoolId,
        addedBy: auth.currentUser.uid,
      };

      const studentDocRef = await addDoc(studentsCollectionRef, newStudentData);

      const classDocRef = doc(db, "classes", selectedClass);
      await updateDoc(classDocRef, {
        enrolledStudents: arrayUnion(studentDocRef.id),
      });
    } catch (error) {
      console.error("Error adding student:", error);
    }
  };

  // Handles file upload and parsing
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const newStudents = parseStudentList(text);
        setParsedStudents(validateStudents(newStudents));
      };
      reader.onerror = () =>
        setFileError("There was an error reading the file.");
      reader.readAsText(file);
    }
  };

  return (
    <div>
      <div className="add-student-form-container">
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="classSelect" style={{ fontSize: "24px" }}>
              Select a Class:
            </label>
            <select
              id="classSelect"
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
              required
              style={{ width: "200px", fontSize: "18px", margin: "20px" }}
            >
              <option value="">-- Select a Class --</option>
              {classList && classList.length > 0 ? (
                classList.map((classData) => (
                  <option key={classData.id} value={classData.id}>
                    {classData.className}
                  </option>
                ))
              ) : (
                <option>No classes available</option>
              )}
            </select>
          </div>

          <div className="side-by-side-container">
            <div className="side-form">
              <h2>Add Individual Student</h2>
              <div>
                <label htmlFor="studentName">Student Name:</label>
                <input
                  type="text"
                  id="studentName"
                  name="studentName"
                  required
                />
              </div>
              <div>
                <label htmlFor="studentId">Student ID:</label>
                <input type="text" id="studentId" name="studentId" required />
              </div>
              <div>
                <label htmlFor="grade">Grade:</label>
                <select
                  id="grade"
                  name="grade"
                  value={selectedGrade}
                  onChange={(e) => setSelectedGrade(e.target.value)}
                  required
                >
                  <option value="">-- Select Grade --</option>
                  {gradeLevelOptions.map((grade) => (
                    <option key={grade} value={grade}>
                      {grade}
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit">Add Student</button>
            </div>

            <div className="side-form">
              <h2>Try the Bulk Importer</h2>
              <label htmlFor="pastedStudentList">
                Name | Student ID # | Grade Level:
              </label>
              <textarea
                value={pastedStudentList}
                onChange={(e) => {
                  setPastedStudentList(e.target.value);
                  const parsed = parseStudentList(e.target.value);
                  setParsedStudents(validateStudents(parsed));
                }}
                placeholder="Paste student list here. Copy from a spreadsheet. Columns should be in this order Name | Student ID | Grade Level"
                style={{
                  height: "200px",
                  fontSize: "16px",
                }}
              />
              <button type="button" onClick={handleBulkImport}>
                Bulk Import Students
              </button>

              {parsedStudents.length > 0 && (
                <div className="preview-container">
                  <h3>Preview Parsed Data:</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>ID</th>
                        <th>Grade</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {parsedStudents.map((student, index) => (
                        <tr key={index}>
                          <td>{student.studentName}</td>
                          <td>{student.id}</td>
                          <td>{student.grade}</td>
                          <td
                            style={{ color: student.isValid ? "green" : "red" }}
                          >
                            {student.isValid ? "Valid" : "Error"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>

      <div>
        <h2>Upload a File (CSV or TXT)</h2>
        <input type="file" accept=".csv,.txt" onChange={handleFileUpload} />
        {fileError && <p>{fileError}</p>}
      </div>
      <DisplayEnrolledStudentsTable
        classList={classList}
        selectedClass={selectedClass}
      />
    </div>
  );
};

export default AddStudent;
