import React from "react";

function Teachers() {
  return (
    <div>
      <h3 style={{ marginTop: "150px" }}>Teachers Placeholder</h3>
      <br></br>
    </div>
  );
}

export default Teachers;
