import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import "./NavDropDown.css";

function NavDropDown() {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      navigate("/loginform");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/71fd46e03680accfc7287aa762b6d6d3a024f4f93eab72158144f170f4d61de3?"
          className="img-2"
          alt="Menu-Icon"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {loggedIn ? (
          <>
            <Dropdown.Item as={Link} to="/">
              Home
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/attendance">
              Attendance
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/classsignoutpage">
              Sign Out
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/emergency">
              Check List
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
          </>
        ) : (
          <>
            <Dropdown.Item as={Link} to="/">
              Home
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/about">
              About
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/teachers">
              Teachers
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/schools">
              Schools
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/register">
              Sign Up
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/loginform">
              Sign In
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default NavDropDown;
