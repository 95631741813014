import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "./FirebaseConfig";

const AllUserClassesWithStudents = ({ userId }) => {
  const [userClassesWithStudents, setUserClassesWithStudents] = useState([]);

  useEffect(() => {
    const fetchUserClassesWithStudents = async () => {
      try {
        console.log("Fetching user classes with students...");

        // Fetch classes taught by the current user
        const classesQuerySnapshot = await getDocs(
          query(
            collection(firestore, "classes"),
            where("teacherId", "==", userId)
          )
        );

        // Check if any classes are returned
        console.log(
          "Classes taught by the current user:",
          classesQuerySnapshot.docs.length
        );

        const userClassesData = [];
        for (const classDoc of classesQuerySnapshot.docs) {
          const classData = { id: classDoc.id, ...classDoc.data() };

          // Fetch enrolled students for the current class
          const enrolledStudents = [];
          for (const studentId of classData.enrolledStudents || []) {
            const studentDoc = await getDocs(
              query(
                collection(firestore, "students"),
                where("studentId", "==", studentId)
              )
            );
            if (!studentDoc.empty) {
              const studentData = {
                id: studentDoc.docs[0].id,
                ...studentDoc.docs[0].data(),
              };
              enrolledStudents.push(studentData);
            }
          }

          classData.enrolledStudents = enrolledStudents;
          userClassesData.push(classData);
        }

        // Check if any classes with students are found
        console.log("Classes with students:", userClassesData.length);

        setUserClassesWithStudents(userClassesData);
      } catch (error) {
        console.error("Error fetching user classes with students:", error);
      }
    };

    fetchUserClassesWithStudents();
  }, [userId]);

  return userClassesWithStudents;
};

export default AllUserClassesWithStudents;
