import React, { useState } from "react";
import useEnrolledStudents from "./useEnrolledStudents";
import {
  collection,
  updateDoc,
  doc,
  getFirestore,
  arrayRemove,
} from "firebase/firestore";

import "./AddStudent.css";

const DisplayEnrolledStudentsTable = ({ classList, selectedClass }) => {
  const { studentIdMap, studentNamesMap, studentGradeMap } =
    useEnrolledStudents();
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleToggleCheckbox = (studentId) => {
    setSelectedStudents((prevSelectedStudents) => {
      if (prevSelectedStudents.includes(studentId)) {
        return prevSelectedStudents.filter((id) => id !== studentId);
      } else {
        return [...prevSelectedStudents, studentId];
      }
    });
  };

  const handleDeleteSelectedStudents = async (classId) => {
    setShowConfirmationModal(false); // Hide the confirmation modal
    try {
      const db = getFirestore();

      // Remove selected students from the enrolledStudents array of the class
      const classDocRef = doc(db, "classes", classId);
      await updateDoc(classDocRef, {
        enrolledStudents: arrayRemove(...selectedStudents),
      });

      // You might also want to update the enrolledClasses array of the student
      // to remove the current classId for each selected student here

      // Clear the selected students after deletion
      setSelectedStudents([]);
    } catch (error) {
      console.error("Error deleting students:", error);
    }
  };

  return (
    <div className="display-enrolled-students-container">
      {classList.map((classData) => (
        <div key={classData.id}>
          {selectedClass === classData.id && ( // Check if selectedClass matches
            <div>
              {classData.enrolledStudents &&
              classData.enrolledStudents.length > 0 ? (
                <div>
                  <button onClick={() => setShowConfirmationModal(true)}>
                    Delete Selected
                  </button>
                  {showConfirmationModal && (
                    <div className="modal">
                      <div className="modal-content">
                        <h2>Confirm Deletion</h2>
                        <p>
                          Are you sure you want to delete the selected students?
                        </p>
                        <div className="modal-actions">
                          <button
                            onClick={() =>
                              handleDeleteSelectedStudents(classData.id)
                            }
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => setShowConfirmationModal(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <table className="enrolled-students-table">
                    <thead>
                      <tr>
                        <th>Student Name</th>
                        <th>Student ID</th>
                        <th>Grade</th>
                        <th>Select</th>
                      </tr>
                    </thead>
                    <tbody>
                      {classData.enrolledStudents.map((sid) => (
                        <tr key={sid}>
                          <td>{studentNamesMap[sid] || "Unknown Student"}</td>
                          <td>{studentIdMap[sid]}</td>
                          <td>{studentGradeMap[sid]}</td>
                          <td>
                            <input
                              type="checkbox"
                              onChange={() => handleToggleCheckbox(sid)}
                              checked={selectedStudents.includes(sid)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No enrolled students.</p>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DisplayEnrolledStudentsTable;
