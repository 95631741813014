import React, { useState, useEffect } from "react";
import DisplayEnrolledStudentsTable from "./DisplayEnrolledStudentsTable";
import AllUserClassesWithStudents from "./AllUserClassesWithStudents";
import useFetchClasses from "./useFetchClasses"; // Import the hook to fetch classes

import "./AddStudent.css";

const DisplayEnrolledStudents = ({ userId }) => {
  const [selectedClass, setSelectedClass] = useState(null);
  const { classList: userClasses } = useFetchClasses(); // Fetch classes taught by the current user
  const [userClassesWithStudents, setUserClassesWithStudents] = useState([]); // Define state for user classes with students
  const [combinedClassList, setCombinedClassList] = useState([]); // Define combinedClassList state

  useEffect(() => {
    if (userId) {
      // Fetch all classes with students for the current user
      const fetchUserClassesWithStudents = async () => {
        try {
          const classesWithStudents = await AllUserClassesWithStudents({
            userId,
          });
          setUserClassesWithStudents(classesWithStudents);
        } catch (error) {
          console.error("Error fetching user classes with students:", error);
        }
      };

      fetchUserClassesWithStudents();
    }
  }, [userId]);

  useEffect(() => {
    // Combine user classes with classes with students
    const fetchOtherClasses = async () => {
      try {
        const otherClasses = await userClassesWithStudents.filter(
          (classData) =>
            !userClasses.some((userClass) => userClass.id === classData.id)
        );
        setCombinedClassList([...userClasses, ...otherClasses]);
      } catch (error) {
        console.error("Error fetching other classes:", error);
      }
    };

    fetchOtherClasses();
  }, [userClasses, userClassesWithStudents]);

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  return (
    <div className="display-enrolled-students-container">
      <h2>Enrolled Students</h2>

      <div>
        <label htmlFor="classDropdown">Select Class:</label>
        <select
          id="classDropdown"
          value={selectedClass}
          onChange={handleClassChange}
        >
          <option value="">Select a class</option>
          <option value="all">All Students</option>
          {/* Options for all classes */}
          {combinedClassList.map((classData) => (
            <option key={classData.id} value={classData.id}>
              {classData.className}
            </option>
          ))}
        </select>
      </div>

      {selectedClass ? (
        selectedClass === "all" ? (
          // Check if "All Students" option is selected
          <DisplayEnrolledStudentsTable
            classList={userClassesWithStudents.flat()} // Pass flattened array of all enrolled students
            selectedClass={selectedClass}
          />
        ) : (
          <DisplayEnrolledStudentsTable
            classList={combinedClassList}
            selectedClass={selectedClass}
          />
        )
      ) : (
        <p>Please select a class or all students.</p>
      )}
    </div>
  );
};

export default DisplayEnrolledStudents;
