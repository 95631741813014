import React, { useState, useEffect } from "react";
import { collection, getDocs, doc } from "firebase/firestore";
import { firestore } from "./FirebaseConfig";
import { getAuth } from "firebase/auth";
import useFetchAllClasses from "./useFetchAllClasses";
import useEnrolledStudents from "./useEnrolledStudents"; // Import the custom hook
import useFetchStudents from "./useFetchStudents";
import AttendanceTable from "./AttendanceTable";

function AttendanceRecord() {
  const { classList } = useFetchAllClasses();
  const [selectedClassId, setSelectedClassId] = useState("");
  const { students } = useFetchStudents(selectedClassId);

  const handleClassChange = (event) => {
    const selectedId = event.target.value;
    setSelectedClassId(selectedId);
  };

  const [attendanceData, setAttendanceData] = useState({});

  useEffect(() => {
    async function fetchAttendanceData() {
      try {
        if (!selectedClassId) {
          console.log("No class selected.");
          return;
        }

        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          console.log("User not logged in.");
          return;
        }

        const classRef = doc(firestore, "classes", selectedClassId);
        const attendanceCollectionRef = collection(classRef, "attendance");

        const attendanceQuerySnapshot = await getDocs(attendanceCollectionRef);
        console.log("attendanceQuerySnapshot", attendanceQuerySnapshot);

        const updatedAttendanceData = {}; // Initialize the updated attendance data object

        for (const attendanceDoc of attendanceQuerySnapshot.docs) {
          const date = attendanceDoc.id; // Get the date from the document ID
          console.log("Date: ", date);

          const attendanceForDate = {}; // Initialize the attendance data for this date

          const studentsCollectionRef = collection(
            attendanceDoc.ref,
            "students"
          );
          const studentsQuerySnapshot = await getDocs(studentsCollectionRef);
          console.log("studentsQuerySnapshot:", studentsQuerySnapshot);

          studentsQuerySnapshot.forEach((studentDoc) => {
            const studentId = studentDoc.id; // Get the student's ID
            attendanceForDate[studentId] = true; // Mark the student as present
          });

          // Add the attendance data for this date to the main updatedAttendanceData object
          updatedAttendanceData[date] = attendanceForDate;
        }

        setAttendanceData(updatedAttendanceData); // Update the state with parsed data
        console.log("Updated Attendance Data:", updatedAttendanceData);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    }

    fetchAttendanceData();
  }, [selectedClassId]);

  return (
    <div className="attendance-record-container">
      <h2>Attendance Record</h2>
      <div>
        <label>Select Class:</label>
        <select onChange={handleClassChange}>
          <option value="">Select a class</option>
          {classList.map((cls) => (
            <option key={cls.id} value={cls.id}>
              {cls.className}
            </option>
          ))}
        </select>
      </div>
      {selectedClassId && (
        <div>
          <h2>Attendance Data for Class {selectedClassId}</h2>
          <table>
            <thead>
              <tr>
                <th>Student Name</th>
                {Object.keys(attendanceData).map((date) => (
                  <th key={date}>{date}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.id}>
                  <td>{student.studentName}</td>
                  {Object.keys(attendanceData).map((date) => {
                    const attendanceDataForDate = attendanceData[date];
                    const attendanceValue = attendanceDataForDate[student.id];

                    return (
                      <td key={date}>
                        {attendanceValue ? (
                          <span className="attendance-mark">&#x2714;</span>
                        ) : (
                          "Absent"
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <AttendanceTable />
    </div>
  );
}

export default AttendanceRecord;
