import React, { useEffect } from "react";
import DisplaySignOut from "./DisplaySignOut";
import SignOutForm from "./SignOutForm";
import { useUser } from "./UserContext";

function ClassSignOutPage() {
  const { user, loading } = useUser();

  useEffect(() => {
    const updateContent = () => {
      // Ensure that the element with the specified ID exists
      const targetElement = document.getElementById("exampleElementId");

      if (targetElement) {
        // Set the textContent only if the element is present
        targetElement.textContent = "Updated content";
      }
    };

    // Run the updateContent function after the component has mounted
    updateContent();
  }, []); // Empty dependency array means this effect runs once after initial render

  if (loading) {
    return <div>Loading...</div>;
  }

  const classroomName =
    user && user.displayName ? `${user.displayName}'s Classroom` : "Classroom";

  return (
    <div className="content-container">
      <SignOutForm user={user} />
      <DisplaySignOut user={user} />
    </div>
  );
}

export default ClassSignOutPage;
