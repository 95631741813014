import React, { useState, useRef, useContext, useEffect } from "react";
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  Timestamp,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { firestore, serverTimestamp } from "./FirebaseConfig";
import useFetchClasses from "./useFetchClasses";
import useFilteredStudents from "./useFilteredStudents";
import { UserContext, useUser } from "./UserContext";
import "./Attendance.css"; // Import your custom CSS file

const Attendance = () => {
  const [selectedClassId, setSelectedClassId] = useState("");
  const [studentIdInput, setStudentIdInput] = useState(""); // New state for student ID input
  const { classList } = useFetchClasses();
  const { notPresentStudents, presentStudents, refreshFilteredStudents } =
    useFilteredStudents(selectedClassId);
  const studentIdInputRef = useRef();
  const isDisabled = false; // Replace `false` with the appropriate value

  useEffect(() => {
    if (classList.length === 1) {
      setSelectedClassId(classList[0].id);
    }
  }, [classList]);

  const handleClassChange = (event) => {
    const selectedId = event.target.value;
    setSelectedClassId(selectedId);
  };

  const markAttendance = async (sid, presentStudents) => {
    try {
      if (!selectedClassId) {
        console.log("No class selected for attendance marking.");
        return;
      }

      const classRef = doc(firestore, "classes", selectedClassId);
      const attendanceCollectionRef = collection(classRef, "attendance"); // Update to "attendance"

      const currentDate = new Date().toISOString().slice(0, 10);
      const attendanceDocRef = doc(
        attendanceCollectionRef,
        currentDate // Use current date as the document ID
      );

      // Update to use serverTimestamp() directly
      const currentTimestamp = serverTimestamp();
      console.log("Current Timestamp before setting:", currentTimestamp); // Add this line

      // Update the attendance data structure
      const attendanceData = {
        timestamp: currentTimestamp,
        sid: sid,
      };
      console.log("Data: ", attendanceData);

      // Create a subcollection for each student
      const studentAttendanceCollectionRef = collection(
        attendanceDocRef,
        "students"
      );

      // Check if the student attendance document exists
      const studentAttendanceDocRef = doc(studentAttendanceCollectionRef, sid);

      if (presentStudents) {
        // Delete the document if it exists
        await deleteDoc(studentAttendanceDocRef);
        console.log("Attendance record deleted for student (sid):", sid);
      } else {
        // Create a document for the student's attendance data
        await setDoc(studentAttendanceDocRef, attendanceData, { merge: true });
        console.log("Attendance marked for student (sid):", sid);
        console.log("22 Timestamp before setting:", currentTimestamp); // Add this line
      }

      refreshFilteredStudents();
    } catch (error) {
      console.error("Error marking/deleting attendance:", error);
    }
  };

  // Function to handle manual student ID input
  const handleStudentIdInput = (event) => {
    setStudentIdInput(event.target.value);
  };

  const handleMarkByStudentId = async () => {
    try {
      if (!selectedClassId) {
        console.log("No class selected for attendance marking.");
        return;
      }

      const studentsCollectionRef = collection(firestore, "students");
      const q = query(
        studentsCollectionRef,
        where("studentId", "==", studentIdInput.trim())
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No student found with the given student ID.");
        return;
      }

      const studentDoc = querySnapshot.docs[0];
      const sid = studentDoc.id;
      console.log("Student Doc: ", studentDoc);
      console.log("Student Id: ", sid);

      if (sid) {
        markAttendance(sid);
        setStudentIdInput("");
        studentIdInputRef.current.focus();
      } else {
        console.log("Student not found in the filtered list.");
      }
    } catch (error) {
      console.error("Error marking attendance:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleMarkByStudentId();
    }
  };

  const formatTimestamp = (timestamp) => {
    console.log("Timestamp in format: ", timestamp);

    const options = {
      hour: "numeric",
      minute: "numeric",
    };

    return new Date(timestamp).toLocaleString(undefined, options);
  };

  return (
    <div className="attendance-container">
      <h2>Mark Attendance for Today</h2>
      {classList.length === 1 ? (
        <div>
          <label>Class:</label>
          <span>{classList[0].className}</span>
        </div>
      ) : (
        <div>
          <label>Select Class:</label>
          <select className="class-select" onChange={handleClassChange}>
            <option value="">Select a class</option>
            {classList.map((cls) => (
              <option key={cls.id} value={cls.id}>
                {cls.className}
              </option>
            ))}
          </select>
        </div>
      )}
      {isDisabled && (
        <div className="manual-attendance">
          <label>Enter Student ID:</label>
          <input
            type="text"
            value={studentIdInput}
            onChange={handleStudentIdInput}
            onKeyPress={handleKeyPress}
            ref={studentIdInputRef}
          />
          <button className="blue-buttons" onClick={handleMarkByStudentId}>
            Mark Attended
          </button>
        </div>
      )}

      {/* Not Present Students */}
      <div className="student-heading"></div>
      <div className="student-grid">
        {notPresentStudents
          .sort((a, b) => {
            // Check if lastName is defined for both objects
            if (a.lastName && b.lastName) {
              return a.lastName.localeCompare(b.lastName); // Sort by last name if both last names are defined
            } else if (!a.lastName && b.lastName) {
              return 1; // Place the object with undefined lastName after the one with defined lastName
            } else if (a.lastName && !b.lastName) {
              return -1; // Place the object with defined lastName before the one with undefined lastName
            } else {
              // If both lastName properties are undefined, or if they are equal, compare by another property (e.g., studentName)
              return a.studentName.localeCompare(b.studentName);
            }
          })
          .map((student) => (
            <div
              key={student.id}
              className="student-item not-present"
              onClick={() => markAttendance(student.id)}
            >
              <span className="student-name">{student.studentName}</span>
            </div>
          ))}
      </div>

      {/* Present Students */}
      <div className="student-heading">
        <hr /> {/* Add the horizontal line */}
        <h3>Checked In</h3>
        <hr /> {/* Add the horizontal line */}
      </div>
      <div className="student-grid">
        {presentStudents
          .sort((a, b) => {
            // Check if lastName is defined for both objects
            if (a.lastName && b.lastName) {
              return a.lastName.localeCompare(b.lastName); // Sort by last name if both last names are defined
            } else if (!a.lastName && b.lastName) {
              return 1; // Place the object with undefined lastName after the one with defined lastName
            } else if (a.lastName && !b.lastName) {
              return -1; // Place the object with defined lastName before the one with undefined lastName
            } else {
              // If both lastName properties are undefined, or if they are equal, compare by another property (e.g., studentName)
              return a.studentName.localeCompare(b.studentName);
            }
          })
          .map((student) => (
            <div
              key={student.id}
              className="student-item-present"
              onClick={() => markAttendance(student.id, true)}
            >
              <div className="present-content">
                <span className="student-name">{student.studentName}</span>
                <div className="timestamp-container">
                  <span className="timestamp smaller-timestamp">
                    {formatTimestamp(student.timestamp)}
                  </span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Attendance;
