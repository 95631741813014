import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "./FirebaseConfig";

const AttendanceTable = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const classesCollectionRef = collection(firestore, "classes");
        const querySnapshot = await getDocs(classesCollectionRef);
        const data = [];

        for (const classDoc of querySnapshot.docs) {
          const className = classDoc.id;
          const attendanceCollectionRef = collection(
            classDoc.ref,
            "attendance"
          );

          const attendanceSnapshot = await getDocs(attendanceCollectionRef);

          attendanceSnapshot.forEach((attendanceDoc) => {
            const date = attendanceDoc.id;
            const studentsData = attendanceDoc.data().students;

            const students = Object.keys(studentsData).map((sid) => ({
              sid,
              present: studentsData[sid].present, // Make sure this is correctly set
            }));

            data.push({ className, date, students });
          });
        }

        setAttendanceData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const students = attendanceData.length > 0 ? attendanceData[0].students : [];
  const dates = attendanceData.map((entry) => entry.date);

  return (
    <table border="1">
      <thead>
        <tr>
          <th>Student SID</th>
          {dates.map((date) => (
            <th key={date}>{date}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {students.map((student) => (
          <tr key={student.sid}>
            <td>{student.sid}</td>
            {dates.map((date) => (
              <td key={date}>
                {attendanceData
                  .find(
                    (entry) =>
                      entry.date === date &&
                      entry.className === student.className
                  )
                  ?.students.find((s) => s.sid === student.sid)?.present
                  ? "✔"
                  : "✘"}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AttendanceTable;
