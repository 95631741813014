import React, { useState } from "react";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "./FirebaseConfig";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom"; // Import the Link component

const AddSchoolModal = ({ onClose }) => {
  const navigate = useNavigate(); // Get the navigation function

  const [schoolName, setSchoolName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Add the school document to the "schools" collection
      const schoolsCollectionRef = collection(firestore, "schools");
      const schoolDocRef = doc(schoolsCollectionRef);

      // Add the school data to the document
      await setDoc(schoolDocRef, {
        schoolName,
        address,
        city,
        state,
        zipcode,
        teachers: [getAuth().currentUser.uid], // Add teacher's UID to the teachers array
      });

      // Update schoolId in user's document
      const userDocRef = doc(firestore, "users", getAuth().currentUser.uid);
      await updateDoc(userDocRef, {
        schoolId: schoolDocRef.id,
      });

      onClose(); // Close the modal

      navigate("/dashboard"); // Navigate to the dashboard
    } catch (error) {
      console.error("Error adding school:", error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Add School</h2>
        <form onSubmit={handleSubmit}>
          {/* School Name */}
          <div className="form-row">
            <input
              type="text"
              id="schoolName"
              value={schoolName}
              onChange={(e) => setSchoolName(e.target.value)}
              required
              placeholder="School Name"
            />
          </div>
          {/* Address */}
          <div className="form-row">
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
              placeholder="Address"
            />
          </div>
          {/* City */}
          <div className="form-row">
            <input
              type="text"
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
              placeholder="City"
            />
          </div>

          {/* State */}
          <div className="form-row">
            <input
              type="text"
              id="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
              required
              placeholder="State"
            />
          </div>

          {/* Zipcode */}
          <div className="form-row">
            <input
              type="text"
              id="zipcode"
              value={zipcode}
              onChange={(e) => setZipcode(e.target.value)}
              required
              placeholder="Zip Code"
            />
          </div>

          <div className="form-row">
            <button type="submit">Add School</button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSchoolModal;
