import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "./forms.css"; // Import the forms.css file

const LogInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const auth = getAuth(); // Initialize the auth object
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const ERROR_MESSAGE = "There is a problem logging in";

  const handleLogIn = (e) => {
    e.preventDefault();

    console.log("Logging in with email:", email);
    console.log("Logging in with password:", password);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Log in successful!", userCredential.user);
        // Navigate to the dashboard page
        navigate("/dashboard");
      })
      .catch((error) => {
        setError(error.message);
        console.error("Error logging in:", error);
      });
  };

  return (
    <div className="form-container">
      <form className="login-form" onSubmit={handleLogIn}>
        <div className="form-row">
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email"
          />
        </div>
        <div className="form-row">
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Password"
          />
        </div>
        <div className="form-row">
          <button type="submit">Log In</button>
        </div>
        {error && <div className="error-message">{ERROR_MESSAGE}</div>}
      </form>
    </div>
  );
};

export default LogInForm;
