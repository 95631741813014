import React from "react";
import BasicExample from "./NavDropDown";
import LandingPage from "./LandingPage";
import HeroSection from "./HeroSection";
import MiddleContent from "./MiddleContent";
import "./HeroSection.css"; // Import the CSS file

import "./beta_styles.css";

function Home() {
  return (
    <div>
      <HeroSection />
      <MiddleContent />
    </div>
  );
}

export default Home;
