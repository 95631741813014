import React from "react";

function Schools() {
  return (
    <div>
      <h3 style={{ marginTop: "150px" }}>Schools Placeholder</h3>
      <br></br>
    </div>
  );
}

export default Schools;
