// useEnrolledClassesDropdown.js

import { useState, useEffect } from "react";
import { firestore } from "./FirebaseConfig";
import {
  query,
  where,
  getDocs,
  collection,
  doc,
  getDoc,
} from "firebase/firestore";

const useEnrolledClassesDropdown = () => {
  const [enrolledClasses, setEnrolledClasses] = useState([]);

  useEffect(() => {
    const fetchEnrolledClasses = async () => {
      try {
        // Fetch all students in the school
        const allStudentsQuery = collection(firestore, "students");
        const allStudentsSnapshot = await getDocs(allStudentsQuery);
        const allStudentsData = allStudentsSnapshot.docs.map((doc) =>
          doc.data()
        );

        // Extract unique enrolled classes from all students
        const uniqueEnrolledClasses = Array.from(
          new Set(
            allStudentsData.reduce(
              (classes, student) =>
                classes.concat(student.enrolledClasses || []),
              []
            )
          )
        );

        // Fetch class names and IDs based on class IDs
        const classInfoPromises = uniqueEnrolledClasses.map(async (classId) => {
          const classDocRef = doc(firestore, "classes", classId);
          const classDocSnapshot = await getDoc(classDocRef);

          if (classDocSnapshot.exists()) {
            const className = classDocSnapshot.get("className");
            return { classId, className };
          } else {
            return null;
          }
        });

        const classInfoArray = await Promise.all(classInfoPromises);

        // Remove null values and set the enrolledClasses state
        setEnrolledClasses(classInfoArray.filter(Boolean));
      } catch (error) {
        console.error("Error fetching enrolled classes:", error);
        setEnrolledClasses([]);
      }
    };

    fetchEnrolledClasses();
  }, []);

  return { enrolledClasses };
};

export default useEnrolledClassesDropdown;
