import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import Link here
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import "./NavBar.css";
import LogInForm from "./LogInForm";
import RegistrationForm from "./RegistrationForm";
import NavDropDown from "./NavDropDown";
import LogInRegisterModal from "./LogInRegisterModal";
import RegisterModal from "./RegisterModal";

function NavBar() {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const openRegisterModal = () => {
    setShowLoginModal(false); // Close the login modal
    setShowRegisterModal(true); // Open the registration modal
  };

  const openLoginModal = () => {
    setShowLoginModal(true); // Open the login modal
    setShowRegisterModal(false); // Close the registration modal
  };

  const closeModals = () => {
    setShowLoginModal(false);
    setShowRegisterModal(false);
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoggedIn(!!user);
    });
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // Handle logo click based on login status
  const handleLogoClick = () => {
    if (loggedIn) {
      navigate("/dashboard"); // Redirect to dashboard if logged in
    } else {
      navigate("/"); // Redirect to homepage if not logged in
    }
  };

  return (
    <div className="navbar-container">
      {isSmallScreen ? (
        <div className="div">
          <div className="div-2">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/258d6d1aee6af2a890868e69b1426fbf40bcfa0a1d0cfeb6f306ff216535e9b4?"
              className="img"
              alt="Logo" // Add alt text here
            />
          </div>
          <div className="div-menu">
            <NavDropDown />
          </div>
        </div>
      ) : (
        <div className="div">
          <div className="div-2">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/258d6d1aee6af2a890868e69b1426fbf40bcfa0a1d0cfeb6f306ff216535e9b4?"
              className="img"
              alt="Logo" // Add alt text here
              onClick={handleLogoClick} // Trigger logo click behavior
              style={{ cursor: "pointer" }} // Make it clear it's clickable
            />
          </div>
          <div className="div-3">
            {loggedIn ? (
              <>
                <div className="div-4">
                  <Link to="/attendance" className="div-5">
                    Attendance
                  </Link>
                  <Link to="/classsignoutpage" className="div-6">
                    Sign Out
                  </Link>
                  <Link to="/emergency" className="div-7">
                    Check List
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="div-4">
                  <Link to="/about" className="div-5">
                    About
                  </Link>
                  <Link to="/teachers" className="div-6">
                    Teachers
                  </Link>
                  <Link to="/schools" className="div-7">
                    Schools
                  </Link>
                </div>
              </>
            )}
          </div>
          {loggedIn ? (
            <>
              <div className="div-8-logged-in">
                <div className="div-9">
                  <div className="div-11" onClick={handleLogout}>
                    Log Out
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="div-8">
                <div className="div-9">
                  <div className="div-10" onClick={openRegisterModal}>
                    Sign Up
                  </div>
                  <RegisterModal show={showRegisterModal} onHide={closeModals}>
                    {showRegisterModal && <RegistrationForm />}
                  </RegisterModal>
                  <div className="div-11" onClick={openLoginModal}>
                    Sign In
                  </div>
                  <LogInRegisterModal
                    show={showLoginModal}
                    onHide={closeModals}
                  >
                    {showLoginModal && <LogInForm />}
                  </LogInRegisterModal>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default NavBar;
