import { useState, useEffect } from "react";
import { doc, collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "./FirebaseConfig";

const useFilteredStudents = (classId) => {
  const [notPresentStudents, setNotPresentStudents] = useState([]);
  const [presentStudents, setPresentStudents] = useState([]);

  const fetchFilteredStudents = async () => {
    try {
      if (!classId) {
        console.log("Invalid class ID. Skipping fetchFilteredStudents.");
        return;
      }

      const currentDate = new Date().toISOString().slice(0, 10);
      const classRef = doc(firestore, "classes", classId);
      const attendanceCollectionRef = collection(classRef, "attendance");
      const attendanceDocRef = doc(attendanceCollectionRef, currentDate);
      const studentAttendanceCollectionRef = collection(
        attendanceDocRef,
        "students"
      );

      const attendedSnapshot = await getDocs(studentAttendanceCollectionRef);
      const attendedStudentIds = attendedSnapshot.docs.map((doc) => doc.id);

      const studentsCollectionRef = collection(firestore, "students");
      const q = query(
        studentsCollectionRef,
        where("enrolledClasses", "array-contains", classId)
      );
      const studentsSnapshot = await getDocs(q);

      const allStudents = studentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const presentStudentsData = allStudents
        .filter((student) => attendedStudentIds.includes(student.id))
        .map((student) => {
          const attendedStudent = attendedSnapshot.docs.find(
            (doc) => doc.id === student.id
          );
          return {
            id: student.id,
            timestamp: attendedStudent
              ? attendedStudent.data().timestamp.toDate()
              : null,
            ...student,
          };
        });

      const notPresentStudentsData = allStudents.filter(
        (student) => !attendedStudentIds.includes(student.id)
      );

      setPresentStudents(presentStudentsData);
      setNotPresentStudents(notPresentStudentsData);
    } catch (error) {
      console.error("Error fetching filtered students:", error);
      setPresentStudents([]); // Reset present students to an empty array in case of an error.
      setNotPresentStudents([]); // Reset not present students to an empty array in case of an error.
    }
  };

  useEffect(() => {
    fetchFilteredStudents();
  }, [classId]);

  const refreshFilteredStudents = () => {
    fetchFilteredStudents(); // Make sure to call the function here
  };

  return { presentStudents, notPresentStudents, refreshFilteredStudents };
};

export default useFilteredStudents;
